<template>
  <div class="find-stroke-tarin-container">
    <div
      class="find-stroke-tarin-content position-relative"
      :class="'game-' + gameIndex"
    >
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        :numBers="currentStep"
        @clickNext="nextPage"
      ></PageButton>
        <Star :totalStarNumber="hanziStrokeInfo.length" :currentIndex="currentStep" />
      <!-- 红框笔画 -->
      <div class="right-gules">
        <div class="pinyin font-pinyin-medium stroke-pinyin">
          {{ strokePinyin }}
        </div>
        <div class="target-stroke-hanzi">
          {{strokeHanzi}}
        </div>
      </div>

      <div class="header-img-box" ref="trainBox">
        <!-- 头部灯背景 -->
        <div class="header-img-content">
          <img
            v-show="currentStep === 0"
            :src="headerBgImg.defaultImg"
            alt=""
          />
          <!-- </template> -->
          <img
            v-show="currentStep >= lightIndex[0] && currentStep < lightIndex[1]"
            :src="headerBgImg.firstImg"
            alt=""
          />
          <img
            v-show="currentStep >= lightIndex[1] && currentStep < lightIndex[2]"
            :src="headerBgImg.secondImg"
            alt=""
          />
          <img
            v-show="currentStep === lightIndex[2]"
            :src="headerBgImg.thirdImg"
            alt=""
          />
          <!-- 笔顺部分 -->
          <div class="hanzi-tarin-content" ref="strokeTarinContent">
            <div
              class="typeface-picture"
              v-for="(item, index) in hanziStrokeInfo"
              :key="index + 'hanziStroke'"
            >
              <div class="block" @click="clickedStroke(item.index, index)">
                  <img :src="item.strokeBgImg" alt="" />
              </div>
            
              <div
                v-if="index !== 0"
                class="cloud"
                :class="{ fadeout: currentCloudIndex >= index }"
              >
                <img :src="shadowImg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "../Star";

export default {
  components: {
    PageButton,
    Star,
  },
  props: {
    shadowImg: {
      type: String,
      require: true,
    },
    strokeHanzi: {
      type: String,
      require: true,
    },
    gameIndex: {
      type: Number,
      require: true,
    },
    hanziStrokeInfo: {
      type: Array,
      require: true,
    },
    lightIndex: {
      type: Array,
      require: true,
    },
    strokePinyin: {
      type: String,
      require: true,
    },
    headerBgImg: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      scrollDom: null,
      distance: 0,
      currentStep: 0,
      currentLigthIndex: 0,
      instructionsDisplay: true,
      currentCloudIndex: 0,
      strokeTrainInfo: {},
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      clickedStrokeValue: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 0,
          },
        ],
      },
    };
  },
  mounted() {
    this.$bus.$on(
      "clickRightStrokeArea",
      ({ strokeIndex, hanziIndex, singleStrokeIndex }) => {
        this.clickedStroke(strokeIndex, hanziIndex, singleStrokeIndex, 1);
      }
    );
    this.getScrollWidth();
  },
  beforeDestroy() {
    this.$bus.$off("clickRightStrokeArea");
  },
  watch: {
    clickedStrokeValue: {
      handler(value) {
        const data = {
          clickType: 20013,
          data: { value: this.strokeTrainInfo },
          text: "FindStrokeTrainGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    getScrollWidth() {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.trainBox;
      });
    },
    clickedStroke(strokeIndex, hanziIndex,singleStrokeIndex,type) {
      if (type != 1) {
        this.clickedStrokeValue++;
      }
      // playCorrectSound(true, false);
      let ref = "stroke_" + strokeIndex;
      this.currentStep++;
      if (this.lightIndex.includes(this.currentStep)) {
        if (this.currentStep === 1) {
          this.instructionsDisplay = false;
        }
        this.trainScroll(hanziIndex);
      }
      // this.$refs[ref][0].style.pointerEvents = "none";
      // this.$emit("showStroke", hanziIndex, singleStrokeIndex);
      this.strokeTrainInfo = { strokeIndex, hanziIndex, singleStrokeIndex };
    },
    // 跳转下一页
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    // 自动移动
    trainScroll(index) {
      if (index === 0) {
        playCorrectSound(true, false);
        const timer = setInterval(() => {
          this.distance += 2;
          this.scrollDom.scrollLeft = this.distance;
          if (
            this.scrollDom.scrollLeft + 1 >=
            (this.scrollDom.scrollWidth - this.scrollDom.clientWidth) / 2
          ) {
            clearInterval(timer);
            this.currentCloudIndex++;
          }
        }, 10);
      } else if (index === 1) {
        playCorrectSound(true, false);
        this.$nextTick(() => {
          const timer = setInterval(() => {
            this.distance += 2;
            this.scrollDom.scrollLeft = this.distance;
            if (
              this.scrollDom.scrollLeft >=
              this.scrollDom.scrollWidth - this.scrollDom.clientWidth - 1
            ) {
              clearInterval(timer);
              this.currentCloudIndex++;
            }
          }, 10);
        });
      } else {
        playCorrectSound();
        startConfetti();
        this.isLastStep = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">

.find-stroke-tarin-container {
  width: 100%;
  height: 100%;
  position: relative;
  .find-stroke-tarin-content {
    width: 100%;
    height: 100%;
    background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;

    .header-img-box {
      width: 100%;
      height: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      border-radius: 58px;
      position: relative;
      .header-img-content {
        height: 100%;
        position: absolute;
        // top: 5vh;
        img {
          display: inline-block;
          height: 35%;
          cursor: pointer;
        }
        .hanzi-tarin-content {
          // position: absolute;
          // bottom: 0;
          height: 63%;
          display: flex;
          align-items: center;
          .typeface-picture {
            width: 16%;
            height: 75%;
            margin-left: 14%;
            background-color: #fff;
            border-radius: 30px;
            position: relative;
            border-right: 10px solid #2258a7;
            border-bottom: 10px solid #2258a7;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            font-family: "STHeitiSCPinyin-Light";
            .block {
              width: 80%;
              height: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              img {
                height: 100%;
              }
              .stroke-block {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  // height: 100%;
                  width: 100%;
                  // cursor: pointer;
                }
                .red-one {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .heng-one {
                    position: absolute;
                    // left:0%;
                  }
                  .heng-two {
                    position: absolute;
                    // left:0%;
                  }
                  .heng-three {
                    position: absolute;
                    // left:0%;
                  }
                }
              }
            }
            span {
              color: #cd4c3f;
              margin-top: 2%;
            }
            .cloud {
              width: 100%;
              position: absolute;
              bottom: 15%;
              left: -10%;
              pointer-events: none;
              img {
                width: 120%;
              }
            }
            .instructions {
              width: 100%;
              position: absolute;
              bottom: 15%;
              left: 50%;
              //  cursor: pointer;
              img {
                width: 50%;
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}
.right-gules {
  width: auto;
  height: auto;
  padding:10px 30px 10px 30px;
  background-color: #cd4c3f;
  position: absolute;
  right: 0;
  top: 15%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    color: #fff;

  .stroke-pinyin {
    flex: 3;
    display: flex;
    align-items: center;
  }
  .target-stroke-hanzi {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size:45px;
   
  }
}
.py-font-size {
  font-size: 1.5em;
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.fadeout {
  color: white;
  -webkit-animation: fadeout 0.5s ease-in forwards;
  -moz-animation: fadeout 0.5s ease-in forwards;
  animation: fadeout 0.5s ease-in forwards;
}
</style>
